/* General styles */
.aboutMe-container {
  padding: 10px 20px;
  margin: 0;
}

.img-para {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.img-para img {
  width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.img-para div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutMe-ldn-para {
  background-color: #ffffff;
  padding: 2px;
  color: #385537;
  font-family: "Helvetica";
  display: flex;
}

.aboutMe-ldn-para h1 {
  color: #385537;
  font-family: "Helvetica";
  letter-spacing: 0.05em;
}

.aboutMe-ldn-para p {
  font-family: "Helvetica";
  letter-spacing: 0.04em;
  font-size: 18px;
}

.about-me {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  background-color: #385537;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.ab-me {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  background-color: #decfb2;
  color: #385537;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.about-me h3 {
  font-family: "Merriweather", serif;
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: 0.05em;
  line-height: 1.5;
  color: white;
}

.expertise {
  background-color: #decfb2;
  padding: 10px 20px;
}

.expertise h5,
.experience h5 {
  letter-spacing: 0.04em;
}

.expertise p,
.experience p {
  letter-spacing: 0.04em;
  line-height: 1.6;
  font-size: 16px;
  padding-left: 17px;
}

.experience {
  padding: 10px;
}

.about-me-expertise {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.grid-item {
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
}

.grid-item i {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #325836;
}

.grid-item p {
  font-family: "Helvetica", serif;
  font-size: 18px;
  line-height: 2rem;
  margin-bottom: 10px;
}

h5 {
  font-size: 23px;
  margin-bottom: 10px;
}

.aboutMe-ldn-para ul {
  list-style: none;
  padding: 0;
  margin-top: 1rem; /* Spacing above the list */
}

.aboutMe-ldn-para ul li {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: #385537; /* Color for the list text */
  margin-bottom: 0.5rem; /* Space between list items */
}

.aboutMe-ldn-para ul li svg {
  color: #385537;
  margin-right: 8px; /* Spacing between icon and text */
}

.fa-user-graduate:before {
  font-size: 29px;
}

.fa-award:before {
  font-size: 32px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .img-para {
    grid-template-columns: 1fr; /* Single column on small screens */
    text-align: left;
  }

  .about-me h3 {
    font-size: 2rem; /* Smaller font on mobile */
  }

  .about-me {
    height: auto; /* Adjust height for mobile */
  }

  .about-me-expertise {
    grid-template-columns: 1fr; /* Single column on small screens */
    gap: 10px;
  }
}

@media screen and (max-width: 480px) {
  .about-me {
    height: auto;
  }

  .about-me h3 {
    font-size: 1.5rem;
  }

  .aboutMe-ldn-para h1 {
    font-size: 1.35rem;
  }
  .aboutMe-ldn-para p {
    font-size: 1.1rem;
  }

  .expertise h5,
  .experience h5 {
    letter-spacing: 0.04em;
  }

  .expertise p,
  .experience p {
    letter-spacing: 0.04em;
    line-height: 1.6;
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1440px) {
  .aboutMe-ldn-para h1 {
    font-size: 2.5rem;
  }

  .aboutMe-ldn-para p {
    font-size: 1.5rem;
  }

  .about-me h3 {
    font-size: 2.5rem;
  }

  .grid-item i {
    font-size: 2rem;
  }

  .grid-item p {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 2rem;
  }

  .aboutMe-ldn-para ul li {
    font-size: 2rem;
  }

  .aboutMe-ldn-para ul li svg {
    font-size: 2rem;
  }
}

@media screen and (min-width: 2560px) {
  .aboutMe-ldn-para h1 {
    font-size: 5rem;
  }

  .aboutMe-ldn-para p {
    font-size: 2.5rem;
  }

  .about-me h3 {
    font-size: 5rem;
  }

  .grid-item i {
    font-size: 2.5rem;
  }

  .grid-item p {
    font-size: 2.5rem;
  }

  h5 {
    font-size: 3rem;
  }

  .about-me {
    height: 45vh;
  }

  .aboutMe-ldn-para ul li {
    font-size: 2.5rem;
  }

  .aboutMe-ldn-para ul li svg {
    font-size: 2.5rem;
  }
}
