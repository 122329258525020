p {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1em;
}

h1,
h3 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #385537;
  margin-bottom: 0.5em;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

a {
  color: #f6670ef3;
  text-decoration: none;
}

a:hover {
  color: #f8ad21f3;
}

.service-container {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */
  padding: 0 40px 40px 0px;
}

.text-sphere {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-text {
  flex: 1;
  padding-left: 40px;
  font-family: "lato", sans-serif;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
}

.tagcloud {
  display: inline-block;
  font-family: "lato", sans-serif;
  font-weight: 600;
  letter-spacing: 0.0625em;
  font-size: 1.3rem;
  border-radius: 50%;
  /* background-image: url(https://th.bing.com/th/id/OIP.R5kqc_7XMsIVGa322NzTugHaHa?w=1024&h=1024&rs=1&pid=ImgDetMain); */
}
.tagcloud--item {
  color: #385537;
}

.tagcloud--item:hover {
  color: #384500;
  cursor: pointer;
}

.deit-mgmnt,
.disease-mgmnt,
.wght-mgmnt {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #333;
} */

.deit-mgmnt,
.disease-mgmnt,
.wght-mgmnt {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.dt-box,
.ds-box,
.wt-box {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
  transition: transform 0.3s ease;
}

.dt-box img,
.ds-box img,
.wt-box img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}

.dt-box h3,
.ds-box h3,
.wt-box h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #555;
}

.dt-box p,
.ds-box p,
.wt-box p {
  justify-content: flex-start;
  padding: 4px;
  flex-grow: 1;
}

.dt-box footer,
.ds-box footer,
.wt-box footer {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #888;
}

.dt-box:hover,
.ds-box:hover,
.wt-box:hover {
  transform: scale(1.05);
}

.service-footer {
  color: white !important;
  background-color: #333;
  border-radius: 4px;
  padding: 12px;
  transition: transform 0.3s ease;
}

.service-footer:hover {
  background-color: #fff;
  color: #f6670ef3 !important;
  transform: scale(1.05);
}

.service-container {
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.service-sidebar {
  display: flex;
  width: 100%;
  flex-basis: auto;
}

.service-sidebar span h5 {
  font-weight: 700;
  padding: 20px 10px;
}

.service-sidebar-list > ul {
  border: 1px solid transparent;
  list-style-type: none;
  padding: 0 2% 10% 10%;
  margin: 0;
}

.service-sidebar-list ul {
  list-style-type: none;
  padding-left: 0;
}

.service-sidebar-list ul li {
  list-style: none;
  line-height: 1.6;
  background: #385537;
  color: #fff;
  padding: 0 3px;
  margin-bottom: 5px;
}

.service-sidebar-list ul li > a,
.service-sidebar-list ul ul li a {
  color: #fff !important;
  font-size: 1.25rem;
  letter-spacing: 0.04em;
  padding: 0 0px;
  text-decoration: none;
}

.service-sidebar-list ul li > a:hover,
.service-sidebar-list ul ul li a:hover {
  color: #385537 !important;
  background: #decfb2;
  transition: color 0.8s ease;
  border-radius: 3px;
}

.dropdown-toggle {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 1.25rem;
}

.dropdown-toggle:hover {
  background: #decfb2;
  color: #385537;
  border-radius: 3px;
}

.dropdown-toggle svg {
  font-size: 14px;
  margin-left: 4px;
}

.services-offered-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-offered-content-wrapper img {
  width: 80%;
  height: auto !important;
}

.services-offered-content-wrapper h2,
.services-offered-content-wrapper p {
  color: #385537;
}

.services-offered-content-wrapper p {
  line-height: 1.6;
  font-size: 1.25rem;
  letter-spacing: 0.04em;
}

.services-offered-content-wrapper button {
  width: auto;
  padding: 16px 20px;
  margin-top: 10px;
  background-color: #385537;
  color: #fff;
  font-size: 1.25rem;
  border-radius: 4px;
}

@media (max-width: 1024px) {
  .deit-mgmnt,
  .disease-mgmnt,
  .wght-mgmnt {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 991px) {
  .service-sidebar {
    display: none;
  }

  .services-offered-content-wrapper p {
    line-height: 1.5;
    /* font-size: 1.15rem; */
    font-size: clamp(1rem, 0.3043rem + 3.4783vw, 1.25rem);
    letter-spacing: 0.03em;
  }

  .services-offered-content-wrapper button {
    font-size: clamp(1rem, 0.3043rem + 3.4783vw, 1.25rem);
  }

  .deit-mgmnt,
  .disease-mgmnt,
  .wght-mgmnt {
    grid-template-columns: 1fr;
  }

  h2 {
    font-size: 2rem;
  }

  .dt-box,
  .ds-box,
  .wt-box {
    padding: 15px;
  }

  .dt-box h3,
  .ds-box h3,
  .wt-box h3 {
    font-size: 1.3rem;
  }

  .services-offered-content-wrapper {
    flex-direction: column;
  }
}

@media (min-width: 2560px) {
  .deit-mgmnt,
  .disease-mgmnt,
  .wght-mgmnt {
    max-width: 80%;
    grid-template-columns: repeat(3, 1fr);
  }

  .dt-box,
  .ds-box,
  .wt-box {
    padding: 30px;
  }

  .dt-box h3,
  .ds-box h3,
  .wt-box h3 {
    font-size: 1.7rem;
  }

  h2 {
    font-size: 3rem;
  }
}

@media (max-width: 1023px) {
  .service-container {
    flex-direction: column;
    height: auto;
  }

  .text-sphere {
    margin-bottom: 30px;
  }

  .service-text {
    padding-left: 0;
    line-height: 1.5;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .service-text p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .tagcloud {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .service-text p {
    font-size: 0.95rem;
  }

  .tagcloud {
    font-size: 1rem;
  }
}
