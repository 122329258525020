/* Base styles */
.nutri-plan-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.nutri-plan-container h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.nutri-plan-container p {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #555;
  margin-bottom: 40px;
}

/* Flexbox layout for service cards */
.services-offered {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.services-offered > div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  transition: transform 0.3s ease;
  gap: 20px;
}

.services-offered > div:hover {
  transform: scale(1.05);
}

.services-offered h3 {
  font-size: 1.5rem;
  color: #385537;
  margin-bottom: 10px;
}

.services-offered p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
}

.services-offered button {
  background-color: #385537;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
}

.services-offered button:hover {
  background-color: #385537;
}

.booking-form {
  margin-top: 40px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.booking-form h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
  padding: 0px 30px;
}

.booking-form form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 0px 30px;
}

.booking-form input,
.booking-form select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.booking-form button {
  padding: 12px;
  font-size: 1.2rem;
  color: white;
  background-color: #385537;
  border: none;
  border-radius: 5px;
  width: 200px;
  justify-self: center;
  cursor: pointer;
  grid-column: span 2;
}

.booking-form button:hover {
  background-color: #385537;
}

@media (max-width: 580px) {
  .booking-form form {
    grid-template-columns: 1fr;
    padding: 0px 15px;
    gap: 10px;
  }

  .booking-form button {
    grid-column: span 1;
  }

  .booking-form h3 {
    font-size: clamp(1.23rem, 1.5vw, 1.5rem);
  }
}

@media (max-width: 767px) {
  .services-offered-content-wrapper {
    flex-direction: column;
  }

  .services-offered img {
    flex: 0 0 100%;
    width: 100%;
    height: auto;
  }

  .services-offered-content {
    flex: 1 1 100%;
  }

  .services-offered h3 {
    font-size: 1.3rem;
  }

  .services-offered p {
    font-size: 0.9rem;
  }
}
