p {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1em;
}

h1,
h3 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.5em;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

/* Links */
a {
  color: #f6670ef3;
  text-decoration: none;
}

a:hover {
  color: #f8ad21f3;
}

/* Contact Me Styles */
.contact-me img {
  width: 100%;
  height: 60vh;
}

.contactMe-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 20px;
}

.contact-info {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 15px;
}

.address,
.mobile,
.email {
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid rgba(213, 207, 207, 0.5);
  border-radius: 4px;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(213, 207, 207, 0.5);
  cursor: pointer; /* Added cursor to indicate clickable elements */
}

.icon {
  color: #385537;
  font-size: 2rem;
}

.contact-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  font-size: 1.8rem;
}

.contact-form p {
  margin-bottom: 20px;
  font-size: 1rem;
}

/* Input Styles */
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.contact-me-sub-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.contact-me-sub-btn > button {
  padding: 10px 30px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contactMe-container {
    grid-template-columns: 1fr; /* Stack columns on smaller screens */
  }

  .contact-info,
  .contact-form {
    padding: 0 10px; /* Reduce padding for smaller screens */
  }
}
