.carousel .slide {
  background-color: #385537;
}

.home-carousel-background {
  background-color: #385537;
  height: 460px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.home-dr-emmany-image {
  height: 50vh;
  width: auto;
  flex-basis: 40%;
  object-fit: contain;
  align-self: flex-end;
}

.home-text-overlay-slide1 {
  flex-basis: 60%;
  color: #ffffff;
  text-align: left;
  padding-left: 20px;
}

@media screen and (max-width: 426px) {
  .home-carousel-background {
    flex-direction: column;
    height: auto;
  }

  .home-dr-emmany-image {
    height: 40vh;
    width: auto;
    align-self: center;
    justify-self: center;
    margin-bottom: 20px;
  }

  .home-text-overlay-slide1 {
    padding-left: 0;
    text-align: center;
    flex-basis: auto;
  }
}

@media screen and (max-width: 769px) {
  .home-carousel-background {
    flex-direction: column;
    height: auto;
  }

  .home-dr-emmany-image {
    height: auto;
    width: auto;
    max-height: 80%;
    align-self: center;
    margin-bottom: 20px;
  }

  .home-text-overlay-slide1 {
    padding-left: 0;
    text-align: center;
    flex-basis: auto;
  }
}

.heading {
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.paragraph {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-top: 0.5rem;
}

.home-text-slide2 {
  margin-top: 40px;
}

.home-typing-text {
  font-size: 2.4rem;
  line-height: 1.2;
  text-transform: uppercase;
}

.home-text-slide2 p {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-top: 10px;
}

.home-about-me h3 {
  font-family: "Merriweather", serif;
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: 0.05em;
  line-height: 1.5;
  color: #385537;
  height: auto;
  display: flex;
  padding: 0 20px;
  margin: 15px 4px;
}

.home-about-me > h3 > span {
  padding: 0 20px;
}

.custom-card {
  position: relative;
  overflow: hidden;
}

.hover-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #decfb2;
  color: #385537;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 10;
}

.hover-button:hover {
  color: #decfb2;
}

.custom-card:hover .hover-button {
  opacity: 1;
  visibility: visible;
  animation: vibrate 2s ease-in-out;
}

.slick-slide img {
  display: block !important;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.blog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 400px;
  box-sizing: border-box;
}

.blog-content img {
  width: 50%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 25px;
}

.blog-content h3 {
  margin: clamp(10px, 2vh, 20px) 0;
  font-size: clamp(1.2rem, 2.2vw, 1.2rem);
}

.blog-content p {
  font-size: clamp(0.9rem, 2vw, 1rem);
  margin-bottom: 5px;
  color: #385537;
}

.blog-content li {
  color: #385537;
  font-size: clamp(0.9rem, 2vw, 1rem);
}

@media (max-width: 768px) {
  .blog-content {
    padding: 15px;
  }

  .blog-content img {
    width: 80%;
  }

  .blog-content h3 {
    font-size: 1.5rem;
  }

  .blog-content p {
    font-size: 1rem;
  }
}

button {
  cursor: pointer;
  padding: clamp(10px, 1.5vw, 20px);
  border: none;
  background-color: #385537;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #274b2e;
}

.blog-container {
  position: relative;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  z-index: 2;
  display: none;
}

.arrow.next {
  right: 10px;
}

.arrow.next:hover {
  color: #385537;
  background-color: #decfb2;
}

.arrow.prev {
  left: 10px;
}

.arrow.prev:hover {
  color: #385537;
  background-color: #decfb2;
}

.blog-container {
  padding: 20px;
  position: relative;
}

.blog-content img {
  width: 50%;
  height: 50%;
  margin-bottom: 10px;
}

.slick-slide {
  padding: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-arrow {
  color: #333;
}

.slick-prev,
.slick-next {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  color: white;
  z-index: 2;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #decfb2;
  color: #385537;
}

@media (max-width: 991px) {
  .blog-content {
    min-width: calc((100% - 20px) / 2);
  }
}

@media (max-width: 576px) {
  .blog-content {
    min-width: 100%;
  }
}

.blog-container {
  padding: 20px;
  height: inherit;
}

.blog-container h1,
.blog-container span > p {
  text-align: center;
}

.blog-container span > p {
  margin-top: 50px;
  color: #385537 !important;
  background: #decfb2;
  width: auto;
  border-radius: 4px;
}

.blog-container span > p:hover {
  color: #decfb2 !important;
  background: #385537;
}

.view-all-container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.view-all-container p {
  margin: 0;
}

.view-all-container a {
  background-color: #decfb2;
  padding: 10px 15px;
  border-radius: 5px;
  color: #385537 !important;
  text-decoration: none;
  font-weight: bold;
}

.view-all-container a:hover {
  background-color: #385537;
  color: #decfb2 !important;
}

@keyframes vibrate {
  0%,
  100% {
    transform: translateX(-50%);
  }
  25% {
    transform: translateX(calc(-50% + 4px));
  }
  50% {
    transform: translateX(calc(-50% - 4px));
  }
  75% {
    transform: translateX(calc(-50% + 2px));
  }
}

@media (max-width: 768px) {
  .hover-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .custom-card:active .hover-button,
  .custom-card:focus .hover-button {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 480px) {
  .heading {
    font-size: 1.5rem;
  }

  .home-about-me h3 {
    font-size: 1.5rem;
    height: auto;
  }

  .paragraph {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .home-about-me h3 {
    font-size: 1.7rem;
    height: auto;
  }
}

@media screen and (max-width: 1440px) {
}

@media screen and (min-width: 2559px) {
  .home-carousel-background {
    height: 80vh;
  }
}
