.blog-template {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  flex-grow: 1;
}

.blog-header {
  text-align: left;
  margin-bottom: 20px;
}

.blog-title {
  font-size: 2rem;
  margin-bottom: 5px;
}

.blog-date,
.blog-keywords {
  color: #666;
  font-size: 0.9rem;
}

.blog-image {
  width: 60%;
  height: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.blog-content {
  line-height: 1.6;
  margin-bottom: 10px;
  height: auto;
}

h2 {
  margin-top: 20px;
  color: #385537;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

.blog-footer {
  margin-top: 30px;
  text-align: center;
}

.social-media-links a {
  margin: 0 10px;
  color: #385537;
  text-decoration: none;
}

.back-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #decfb2;
  color: #333;
  text-decoration: none;
  border-radius: 5px;
}

.NAFLD-list li {
  list-style: numbers;
}
